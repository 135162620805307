@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

$theme-colors: (
    "primary": #444444,
    "secondary": #A6A6A6,
    "success": #5DC27F,
    "danger": #DC4B59,
    "info": #339F92,
    "light": #E7E6E6,
    "dark": #333333,
    "warning": #8FC73E
);
$body-bg: map_get($theme-colors, "light");
$font-family-sans-serif: 'Source Sans Pro';

h1, h2, h3, h4, h5 {
    color: map_get($theme-colors, "dark")
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

// Bootstrap placeholder color
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: map_get($theme-colors, "secondary") !important;
  opacity: 1 !important; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: map_get($theme-colors, "secondary") !important;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: map_get($theme-colors, "secondary") !important;
}

@import "~bootstrap/scss/bootstrap";

